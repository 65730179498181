@import '~src/scss/index.scss';
.artists {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  width: unset;
  max-height: 295rem;
  margin-top: 40rem;
  gap: 16rem;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.artists::-webkit-scrollbar {
  display: none;
}

.desc {
  max-width: 700rem;
  margin-top: 16rem;
}

.container {
  .who {
    position: absolute;
    top: 0;
    left: 10rem;
    width: 112rem;
    height: 55rem;
    opacity: 0;
    transition: opacity .5s ease-in-out;
  }

  &:hover {
    .who {
      opacity: 1;
    }
  }
}

@include mobile {

  .artists {
    margin-top: 24rem;
    gap: 12rem;
  }

  .container {
    .who {
      top: 5rem;
      left: 5rem;
      width: 75rem;
      height: 30rem;
      opacity: 0;

      &_active {
        opacity: 1;
      }
    }

    .desc {
      margin-top: 12rem;
    }
  }
}
