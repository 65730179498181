@import '~src/scss/index.scss';
.home {
  background-image: url('/images/graphics/home.png');
  background-position: 225rem 3rem;
  background-size: 180rem 64rem;
}

.seeAll {
  min-width: 88rem;
}

.edition {
  position: relative;
  min-height: 212rem;
  max-height: 481rem;
  overflow: hidden;
  border-radius: 24rem;

  .overlay {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 14rem 36rem;
    color: $white-100;
    background: linear-gradient(179.53deg, rgba(255, 255, 255, 0) 0.4%, rgba(18, 16, 28, 0.5) 99.6%);
    border-radius: 0 0 24rem 24rem;
    backdrop-filter: blur(20rem);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include mobile {

  .edition {
    .overlay {
      padding: 12rem 16rem;
    }
  }
}
