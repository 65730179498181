@import '~src/scss/index.scss';
.container {
  margin-top: 168rem;
  margin-bottom: 128rem;
  overflow: hidden;
}

.babylon {
  position: relative;
  width: 100%;
  height: 757rem;
  overflow: hidden;
  color: $white-100;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 21.57%, rgba(0, 0, 0, 0) 34.68%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/images/graphics/babylon.webp');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1440rem;
  background-attachment: fixed;
  border-radius: 32rem;

  &.ios {
    background-attachment: unset;
  }
}

.info {
  position: absolute;
  bottom: 64rem;
  left: 64rem;
  max-width: 600rem;
}

.w820 {
  max-width: 820rem;
}

.w580 {
  max-width: 580rem;
}

.customButtons {
  display: flex;
  margin-top: 32rem;
  gap: 24rem;

  a:last-child {
    color: $white-100;
    border-color: $white-100 !important;

    &:hover {
      color: $gray-20;
      border-color: $gray-20;
    }
  }

  a:first-child {
    color: $dark-100;
    background-color: $white-100;
  }
}

@include mobile {

  .container {
    border-radius: 24rem;
    margin-top: 120rem;
  }

  .customButtons {
    flex-direction: column;
    gap: 12rem;
    width: calc(100% - 24rem);
    margin-top: 24rem;
  }

  .info {
    bottom: 22rem;
    left: 24rem;
    max-width: calc(100% - 48rem);
  }

  .w820,
  .w580 {
    max-width: 100%;
  }

  .babylon {
    background-size: cover;
    height: 646rem;
  }
}
