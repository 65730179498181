@import '~src/scss/index.scss';
.listings {
  display: flex;
  flex-direction: row;
  gap: 24rem;
  margin-top: 40rem;
  padding-bottom: 4rem;
  overflow: scroll;
  @include no-scrollbar();
}

.seeAll {
  min-width: 88rem;
}

.btn {
  min-width: unset;
}

@include mobile {

  .listings {
    margin-top: 24rem;
    gap: 12rem;
  }
}
