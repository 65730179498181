@mixin mobile {
  @media (max-width: 999px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1000px) {
    @content;
  }
}

@mixin retina {
  $retina-variable: 'only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)';
  @media #{$retina-variable} { @content; }
}

@mixin retina-3x {
  $retina-variable: 'only screen and (-webkit-min-device-pixel-ratio: 2.5), only screen and (min-resolution: 288dpi)';
  @media #{$retina-variable} { @content; }
}

