@import '~src/scss/index.scss';
.headText {
  margin-top: 8px;
}

.description {
  width: 638rem;
}

.hero {

  .nft {
    position: absolute;
    top: 11rem;
    left: 326rem;
    width: 146rem;
    height: 62rem;
    opacity: 0;
    transition: opacity .5s ease-in-out;
  }

  &:hover {

    .nft {
      opacity: 1;
    }
  }
}

@include mobile {

  .headText {
    max-width: 284rem;
  }

  .description {
    width: 100%;
  }

  .hero {

    .nft {
      top: 58rem;
      left: 55rem;
      width: 89rem;
      height: 38rem;
      opacity: 0;

      &_active {
        opacity: 1;
      }
    }
  }
}
