@import '~src/scss/index.scss';
.container {
  position: relative;
  width: 255rem;
  height: 255rem;
  overflow: hidden;
  border-radius: 16rem;

  .img {
    width: 100%;
  }

  .bar {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 15rem 30rem;
    color: $white-100;
    background: linear-gradient(179.53deg, rgba(255, 255, 255, 0) 0.4%, rgba(18, 16, 28, 0.5) 99.6%);
    border-radius: 0 0 16rem 16rem;
    backdrop-filter: blur(30rem);
  }
}

@include mobile {

  .container {
    width: 165rem;
    height: 165rem;

    .bar {
      padding: 12rem 16rem;
    }
  }
}
