$brand-90: #352aac;
$brand-90: #352aac;
$brand-70: #3c30c4;
$brand-50: #5033ff;
$brand-20: #5d4df5;
$brand-10: #6f61f6;
$brand-5: #edebfd;

$gray-90: #101010;
$gray-70: #202020;
$gray-50: #414141;
$gray-40: #a0a0a0;
$gray-20: #c6c6c6;
$gray-10: #ececec;

$accent-red-50: #ff6363;
$accent-yellow-50: #f2b100;
$accent-yellow-20: #ffd951;
$accent-yellow-10: #fef7e5;
$accent-green-50: #108f63;
$accent-green-30: #05ae71;

$white-100: #ffffff;
$dark-100: #121212;

$gradient: linear-gradient(94.76deg, #ff1640 8.97%, #ff03bb 102.45%);
$gradient-raffle: linear-gradient(94.76deg, #15e3ff 8.97%, #0047ff 43.54%, #ff00c7 81.51%, #ff2e00 102.45%);
$gradient-donat: linear-gradient(94.76deg, #0047ff 23.58%, #ff00c7 81.51%, #ff2e00 102.45%);
